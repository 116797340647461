<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img
                      class="img-fluid for-light"
                      src="../assets/images/logo/logo.png"
                      alt="looginpage"
                  />

                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit="handleSubmit">
                  <h4>注册账号</h4>

                  <div class="form-group">
                    <label class="col-form-label">登录邮箱</label>
                    <input
                        v-model="registerForm.username"

                        class="form-control"
                        type="email"
                        required=""
                        placeholder="Test@gmail.com"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">登录密码</label>
                    <div class="form-input position-relative">

                      <input
                          class="form-control"
                          :type="active?'password':'text'"
                          name="login[password]"
                          required=""
                          v-model="registerForm.password"
                          placeholder="*********"
                      />
                      <div class="show-hide">
                        <span v-if="active == true" @click="active = false">显示</span>
                        <span v-if="active == false" @click="active = true">隐藏</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">联系方式:TG或Whatsapp</label>
                    <input
                        v-model="registerForm.contact"

                        class="form-control"
                        required=""
                        placeholder="Tg 或 Whatsapp"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">推荐码</label>
                    <input
                        v-model="registerForm.invite_code"
                        class="form-control"
                        placeholder="选填"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label"> 图形码</label>
                    <input
                        v-model="registerForm.captcha_code"

                        required="required"
                        placeholder="请输入右侧图形码"
                        class="form-control"
                    >
                    <div class="caption">
                      <img
                          @click="getCaptcha"
                          :src="captcha"
                          style="height: calc(1.5em + 2px + 0.75rem);"></div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input v-model="agree" type="checkbox" id="agree" disabled checked/>
                      <label class="text-muted" for="agree" disabled="">
                        我已阅读并同意
                        <a class="ms-2" href="javascript:void(0)">出海系统服务平台协议</a>
                      </label>
                    </div>
                    <button class="btn btn-primary btn-block" style="width: 100%;" @click="register">
                      创建账号
                    </button>
                  </div>
                  <hr>
                  <p class="mt-4 mb-0">
                    已经有账号？
                    <router-link class="ms-2" tag="a" to="/auth/login">
                      去登录
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {apiCaptcha, apiRegister} from "@/api/register";

export default {
  data() {
    return {
      active: true,
      captcha: '',
      captchaID: '',
      agree:true,
      registerForm: {
        username: '',
        password: '',
        captcha_id: '',
        captcha_code: '',
        contact: '',
        invite_code: '',
      }
    }
  },
  mounted() {
    this.getCaptcha()
  },
  methods: {
    show() {
      this.active = !this.active
    },
    register() {
      apiRegister(this.registerForm).then(r => {
        this.$message({
          message: '注册成功',
          type:'success'
        });
        setTimeout(()=>{
          this.$router.push('/auth/login')
        },1500)
      })
    },
    getCaptcha() {
      apiCaptcha().then(r => {
        this.registerForm.captcha_id = r.captcha_id
        this.captcha = process.env.VUE_APP_CAPTCHA + r.captcha_id
        console.log('apiCaptcha', r)
      })
    },
    handleSubmit(event) {
      event.preventDefault(); // 阻止默认提交行为
      // 处理提交逻辑
      console.log('表单已提交:', this.inputValue);
    }
  }
}
</script>

<style>
.show-hide {
  position: absolute;
  top: 52px;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.caption {
  position: absolute;
  top: 50px;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.show-hide span:before {
  content: "" !important;
}
.login-card .login-main .theme-form input {
  background-color: #f3f3ff;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
</style>