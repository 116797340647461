<template>
  <el-dialog v-model="visible" title="购买端口" width="650">
    <div>
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item label="购买类型">
          <el-radio-group v-model="form.type">
            <el-tooltip class="box-item" effect="light"
                        content="在已到期的情况下，可以进行购买，如果未到期，可进行增加或续时等操作" placement="top">
              <el-radio :disabled="accountInfo.has_expired === true && accountInfo.expired_days >= 0 " value="create"
                        name="type" @change="calculatePrice">购买端口
              </el-radio>
            </el-tooltip>
            <el-tooltip class="box-item" effect="light"
                        content="在未到期的情况下，进行增加端口，计算方式为剩余时长*端口单价*端口数量" placement="top">
              <el-radio value="add" name="type" @change="calculatePrice">增加端口</el-radio>
            </el-tooltip>
            <el-tooltip class="box-item" effect="light"
                        content="在未到期的情况下，进行增加时长，计算方式为增加时长*端口单价*已拥有的端口数量"
                        placement="top">
              <el-radio value="extended" name="type" @change="calculatePrice">续时端口</el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type == 'extended'" label="当前端口数">
          <span>{{ accountInfo.session_limit }}</span>
        </el-form-item>
        <el-form-item v-if="form.type!='extended'" label="购买端口数量" prop="limit">
          <el-input-number v-model="form.limit" :min="5" :max="99999" style="width: 150px"
                           @input="calculatePrice"
                           placeholder="请输入购买端口数量"
                           @change="calculatePrice"/>

          <span style="margin-left: 10px; color: #909399;">
            <el-tag type="info">
           当前端口:{{ accountInfo.session_limit }}变后端口:{{ accountInfo.session_limit + form.limit }}
          </el-tag>
         </span>
        </el-form-item>

        <el-form-item label="购买单价">
          <span>{{ unitPrice }}$/天</span>
        </el-form-item>

        <el-form-item v-if="form.type === 'create' || form.type === 'extended'" label="购买时长" prop="duration">
          <el-select v-model="form.duration" placeholder="购买时长" style="width: 180px" @change="calculatePrice">
            <el-option label="1个月" value="1"/>
            <el-option label="2个月" value="2"/>
            <el-option label="一季度" value="3"/>
            <el-option label="半年" value="6"/>
            <el-option label="一年" value="12"/>
          </el-select>
        </el-form-item>

        <el-form-item v-if="form.type === 'add'" label="剩余时长">
          <span>{{ accountInfo.expired_days }} 天</span>
        </el-form-item>

        <el-form-item label="预计价格" v-if="form.type === 'create'">
          <span>{{ estimatedPrice }}$</span>
        </el-form-item>
        <el-form-item label="预计价格" v-if="form.type === 'add'">
          <span>{{ formattedPrice }}$</span>
        </el-form-item>
        <el-form-item label="预计价格" v-if="form.type === 'extended'">
          <span>{{ formattedPrice }}$</span>
        </el-form-item>

        <el-form-item label="到期日期" v-if="form.type === 'add'">
          <span>{{ accountInfo.expired_at }}</span>
        </el-form-item>
        <el-form-item label="到期日期" v-if="form.type === 'create'">
          <span>{{ expirationDate }}</span>
        </el-form-item>

        <el-form-item label="当前到期时间">
          <span>{{ accountInfo.expired_at }}</span>
        </el-form-item>
        <el-form-item label="到期时间" v-if="form.type === 'extended'">
          <span>{{ expirationDate }}</span>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="purchase">购买</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiComboPrice} from "@/api/combo";
import {getAccount} from "@/api/account";
import {apiCombosOrderSession} from "@/api/comboOrder";

export default {
  name: "sessionDialog",
  data() {
    return {
      visible: false,
      form: {
        type: 'add',
        limit: 5,
        duration: "1",
        currentExpirationDate: null, // 当前到期日期
      },
      comboPrice: [],
      unitPrice: 0,
      discount: 0,
      rules: {
        limit: [
          {required: true, message: '请输入购买端口数量', trigger: 'blur'},
          {type: 'number', min: 1, message: '数量必须大于0', trigger: 'blur'}
        ],
        duration: [
          {required: true, message: '请选择购买时长', trigger: 'change'}
        ]
      },
      accountInfo: {},
      durationMap: {
        1: 30,   // 1个月
        2: 60,   // 2个月
        3: 90,   // 一季度
        6: 180,  // 半年
        12: 365  // 一年
      },
      orderTypeMap: {
        'create': 1,
        'add': 2,
        'extended': 3
      }
    };
  },

  computed: {
    estimatedPrice() {
      if (this.form.limit > 0 && this.form.duration) {
        const days = this.durationMap[this.form.duration] || 0; // 使用 durationMap 获取天数
        const totalPrice = this.form.limit * this.unitPrice * days;
        const discountAmount = totalPrice * (this.discount / 100);
        return (totalPrice - discountAmount).toFixed(2);
      }
      return 0;
    },
    expirationDate() {
      if (this.form.duration) {
        let currentDate = new Date(); // 默认使用当前时间

        if (this.form.type === 'extended' && this.accountInfo.expired_at) {
          // 将字符串转换为 Date 对象
          currentDate = new Date(this.accountInfo.expired_at);
        }

        const days = this.durationMap[this.form.duration] || 0; // 使用 durationMap 获取天数
        currentDate.setDate(currentDate.getDate() + days); // 计算新的到期时间

        // 格式化日期
        const pad = (num) => String(num).padStart(2, '0'); // 补零函数
        const year = currentDate.getFullYear();
        const month = pad(currentDate.getMonth() + 1); // 月份从0开始
        const day = pad(currentDate.getDate());
        const hours = pad(currentDate.getHours());
        const minutes = pad(currentDate.getMinutes());
        const seconds = pad(currentDate.getSeconds());

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
      return '';
    },

    formattedPrice() {
      if (this.form.type === 'extended') {
        // 续时端口的价格计算
        const days = this.durationMap[this.form.duration] || 0; // 使用 durationMap 获取天数
        return (this.accountInfo.session_limit * this.unitPrice * days).toFixed(4);
      } else {
        // 其他类型的价格计算
        const price = this.accountInfo.expired_days * this.unitPrice * this.form.limit;
        return price.toFixed(4);
      }
    },

  },
  methods: {
    defaultChecked() {
      // if (this.accountInfo.has_expired === true || this.accountInfo.expired_days >= 0) {
      //   this.form.type == 'add'
      // }
    },
    dialogVisible() {
      this.defaultChecked();
      this.fetchAccountInfo();
      this.fetchComboPrice();
      this.visible = true;
    }
    ,
    fetchAccountInfo() {
      getAccount({}).then(response => {
        this.accountInfo = response;
        this.form.currentExpirationDate = response.expirationDate; // 假设返回的数据中有到期日期
      });
    }
    ,
    fetchComboPrice() {
      apiComboPrice().then(response => {
        if (Array.isArray(response)) {
          this.comboPrice = response;
          this.calculatePrice();
        } else {
          this.$message.error("获取套餐价格失败：返回的数据格式不正确");
        }
      }).catch(error => {
        this.$message.error(`获取套餐价格失败：${error.message}`);
      });
    }
    ,
    calculateDiscount() {
      let quantity = this.form.limit;
      this.discount = 0;
      this.unitPrice = 0;
      if (this.form.type === 'extended') {
        quantity = this.accountInfo.session_limit
      }

      if (Array.isArray(this.comboPrice)) {
        for (const price of this.comboPrice) {
          if (quantity >= price.start_limit && quantity <= price.end_limit) {
            this.discount = price.discount;
            this.unitPrice = price.unit_price;
            break;
          }
        }
      }
      // 如果没有找到匹配的价格范围，使用最后一个价格范围
      if (this.unitPrice === 0 && this.comboPrice.length > 0) {
        const lastPrice = this.comboPrice[0];
        this.discount = lastPrice.discount;
        this.unitPrice = lastPrice.unit_price;
      }
    }
    ,
    calculatePrice() {
      this.calculateDiscount();
    }
    ,
    purchase() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let message = "";
          if (this.form.type === 'create') {
            message = "购买成功！";
          } else if (this.form.type === 'add') {
            message = "增加端口成功！";
          } else if (this.form.type === 'extended') {
            message = "续时成功！";
          }
          this.handleBuySession()
        } else {
          this.$message.error("请填写正确的表单信息");
        }
      });
    },
    handleBuySession() {
      apiCombosOrderSession({
        item_limit: this.form.limit,
        combo_type: 2,
        order_type: this.orderTypeMap[this.form.type],
        duration: parseInt(this.form.duration),
      }).then(response => {
        this.visible = false;
        this.$message.success("购买成功！");
      }).catch(error => {
        // this.$message.error(error.data ?? error.msg);
      })
    },
    calculateDays(months) {
      const today = new Date();
      const futureDate = new Date(today.getFullYear(), today.getMonth() + months, today.getDate());
      const diffTime = Math.abs(futureDate - today);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  }
}

</script>

<style scoped>
.box-item {
  margin-right: 10px;
}
</style>
