const http = require('@/utils/http')

const Api = {
    SHARE_CODE: '/share_code',
    SHARE_CODE_SESSION_INFO: '/share_code/session_info',
    SHARE_CODE_USE_INFO: '/share_code/use_info',
    SHARE_CODE_CONTACT: '/share_code/contact_info',
    SHARE_CODE_SESSION_CONTACT: '/share_code/session_contact',
    SHARE_CODE_SESSION_LOG: '/share_code/session_log',
    SHARE_CODE_VALID: '/share_code/valid',
}

exports.Api = Api


exports.apiShareCode = (data) => {
    return http.post(Api.SHARE_CODE, {data})
}

exports.apiShareCodeSessionInfo = (data) => {
    return http.post(Api.SHARE_CODE_SESSION_INFO, {data})
}

exports.apiShareCodeUseInfo = (data) => {
    return http.post(Api.SHARE_CODE_USE_INFO, {data})
}

exports.apiShareCodeContact = (data) => {
    return http.post(Api.SHARE_CODE_CONTACT, {data})
}

exports.apiShareCodeSessionContact = (data) => {
    return http.post(Api.SHARE_CODE_SESSION_CONTACT, {data})
}

exports.apiShareCodeSessionLog = (data) => {
    return http.post(Api.SHARE_CODE_SESSION_LOG, {data})
}

exports.apiShareCodeValid = (data) => {
    return http.post(Api.SHARE_CODE_VALID, {data})
}


