<template>
  <div>
    <Breadcrumbs main="" :title="title"/>
    <div class="card social-widget">
      <div class="card-body">
        <div style="display:flex">
          <div class="search">
            <div class="block large-margins">
              <el-date-picker
                  v-model="form.start_time"
                  type="datetime"
                  placeholder="请选择开始日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  date-format="MMM DD, YYYY"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  time-format="HH:mm"
              />
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-date-picker
                  type="datetime"
                  v-model="form.end_time"

                  placeholder="请选择结束日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  date-format="MMM DD, YYYY"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  time-format="HH:mm"
                  style=""
              />
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-input  v-model="form.session_name"  placeholder="请输入用户昵称"/>
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-input v-model="form.nickname"  placeholder="请输入昵称"/>
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-select v-model="form.online_status" placeholder="在线状态" style="width: 180px">
                <el-option label="在线" :value="1"/>
                <el-option label="离线" :value="2"/>
              </el-select>

            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-button type="primary" @click="search">搜索</el-button>
              <el-button type="warning"  @click="form ={}">重置</el-button>
            </div>


          </div>


        </div>

        <div class="large-margins">
          <el-table :data="tableData" border scrollbar-always-on
                    style="width: 100%;margin-top:20px" ref="innerTable">

            <el-table-column align="center" label="APP" width="180">
              <template #default="scope">
                <el-tag v-if="scope.row.app_platform_id==1" type="success">Whatsapp</el-tag>
                <el-tag v-if="scope.row.app_platform_id==2" type="success">Telegram</el-tag>
                <el-tag v-if="scope.row.app_platform_id==3" type="success">Line</el-tag>
                <el-tag v-if="scope.row.app_platform_id==4" type="success">Zalo</el-tag>
                <el-tag v-if="scope.row.app_platform_id==5" type="success">Instagram</el-tag>
                <el-tag v-if="scope.row.app_platform_id==6" type="success">Tiktok</el-tag>

              </template>
            </el-table-column>
            <el-table-column align="center" prop="session_name" label="用户昵称" width="150"/>


            <el-table-column align="center" prop="avatar" label="头像" width="150">
              <template #default="scope">
                <el-avatar
                    :src="scope.row.session_avatar"
                >
                  <template #default>
                    <img src="@/assets/images/user/user.png"/>
                  </template>
                </el-avatar>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="session_nick_name" label="昵称" width="150"/>
            <el-table-column align="center" prop="address" label="首登时间/最后活跃时间" width="180">
              <template #default="scope">
                <div>
                  {{ scope.row.first_login_at }}
                </div>
                <div>
                  {{ scope.row.last_active_at }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="联系人/今日" width="150">
              <template #default="scope">
                <div>
                  {{ scope.row.contact_count }}
                  /
                  {{ scope.row.contact_today_count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="总重复/今日" width="150">
              <template #default="scope">
                <div>
                  {{ scope.row.repeat_count }}
                  /
                  {{ scope.row.repeat_today_count }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="在线状态" width="180">
              <template #default="scope">
                <el-tag v-if="scope.row.online_status==1" type="success">在线</el-tag>
                <el-tag v-if="scope.row.online_status!=1" type="danger">离线</el-tag>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="remark" label="备注" width="150"/>
            <el-table-column align="center" label="操作" fixed="right" width="280">
              <template #default="scope">
<!--                <el-button size="small" type="primary" @click="handleSessionLog(scope.row)" >-->
<!--                  日志记录-->
<!--                </el-button>-->
                <el-button  size="small" type="primary"
                           @click="handleContact(scope.row)">
                  联系人明细
                </el-button>
                <el-button  size="small" type="danger"
                           @click="handleRemove( scope.row)">
                  移除账号
                </el-button>

              </template>
            </el-table-column>
          </el-table>

        </div>
        <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
      </div>
    </div>
    <contact ref="contactsDialog"></contact>
    <session-log ref="sessionLogDialog"></session-log>
  </div>
</template>
<script>

import SessionLog from "@/pages/invitecode/sessionLog.vue";
import Contact from "@/pages/invitecode/contact.vue";
import {ElMessageBox} from "element-plus"
import {apiSubAccountSession,apiSubAccountSessionRemove} from "@/api/sub_account_session";

export default {
  name: "subAccount",
  components: {
    Contact,
    SessionLog,
  },
  data() {
    return {
      id: '',
      row: [],
      tableData: [],
      logsData: [],
      title: '',
      currentPage: 1,
      pageSize2: 10,
      total: 0,
      page: 1,
      page_size: 10,
      form:{}
    };
  },
  created() {
    this.row = this.$route.query
    this.title = '邀请码-' + this.$route.query.account_code
    this.fetchData();
  },
  methods: {
    handleContact(row) {
      this.$refs.contactsDialog.openVisibleDialog(row)
    },
    handleSessionLog(row) {
      this.$refs.sessionLogDialog.openVisibleDialog(row)
    },
    fetchData() {
      apiSubAccountSession({
        sub_account_id: Number(this.row.id),
        page: this.currentPage,
        page_size: this.pageSize2,
        ...this.form
      }).then(t => {
        this.tableData = t.list
        this.total = t.total
      })
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    handleRemove(row) {
      ElMessageBox.confirm(
          "确认要移除账号吗?\r\n其下的统计数据与粉丝通讯录都将删除且不可恢复",
          '警告',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认',
            cancelButtonText: '取消',
          }
      ).then(() => {
        apiSubAccountSessionRemove({
          id: row.id
        }).then(r => {
          this.$message.success('账号移除成功')
          this.fetchData()
        })
      })
    },
    search(){
      this.currentPage = 1

      this.fetchData()
    }
  }
}
</script>
<style scoped>

.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.el-input__wrapper {
  padding: 0 !important;
}

.el-input__prefix {
  padding-left: 10px !important;
}

.search {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  flex: 1.6 1 0%;
  min-width: 350px;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}

.line {
  width: 1px;
  background-color: var(--el-border-color);
}
</style>