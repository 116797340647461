<template>
  <el-dialog title="分享邀请码" width="1200" v-model="visible" show-close>
    <div class="form-row form-bookmark">
    <div class="form-group col-md-12"
         style="padding: 15px 10px 10px; border-width: 1px 1px 0px; border-top-style: solid; border-right-style: solid; border-bottom-style: initial; border-left-style: solid; border-top-color: rgb(238, 238, 238); border-right-color: rgb(238, 238, 238); border-bottom-color: initial; border-left-color: rgb(238, 238, 238); border-image: initial; margin-bottom: 0px; margin-top: 0px;">
      <label
          style="background-color: rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px; min-width: 70px; text-align: center;">
        邀请码:
      </label>
      <label style="margin-left: 20px;">
        LT+ {{subAccountInfo.shareCode}}
      </label>
    </div>
    <div class="form-group col-md-12"
         style="padding: 15px 10px 10px; border-width: 1px 1px 0px; border-top-style: solid; border-right-style: solid; border-bottom-style: initial; border-left-style: solid; border-top-color: rgb(238, 238, 238); border-right-color: rgb(238, 238, 238); border-bottom-color: initial; border-left-color: rgb(238, 238, 238); border-image: initial; margin-bottom: 0px; margin-top: 0px;">
      <label
          style="background-color: rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px; min-width: 70px; text-align: center;">
        会话数:
      </label>
      <label style="margin-left: 20px;">
        {{subAccountInfo.sessionCount}}
      </label>
    </div>
    <div class="form-group col-md-12"
         style="padding: 15px 10px 10px; border-width: 1px 1px 0px; border-top-style: solid; border-right-style: solid; border-bottom-style: initial; border-left-style: solid; border-top-color: rgb(238, 238, 238); border-right-color: rgb(238, 238, 238); border-bottom-color: initial; border-left-color: rgb(238, 238, 238); border-image: initial; margin-bottom: 0px; margin-top: 0px;">
      <label
          style="background-color: rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px; min-width: 70px; text-align: center;">
        链接:
      </label>
      <label style="margin-left: 20px;">
        <a target="_blank" :href="sharUrl">
          打开
        </a>
        |
        <a @click="copyLink">
          复制链接
        </a>
        |
        <a @click="copyAll">
          复制全部
        </a>
      </label>
    </div>
    <div class="form-group col-md-12"
         style="padding: 15px 10px 10px; border-width: 1px 1px 0px; border-top-style: solid; border-right-style: solid; border-bottom-style: initial; border-left-style: solid; border-top-color: rgb(238, 238, 238); border-right-color: rgb(238, 238, 238); border-bottom-color: initial; border-left-color: rgb(238, 238, 238); border-image: initial; margin-bottom: 0px; margin-top: 0px;">
      <label
          style="background-color: rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px; min-width: 70px; text-align: center;">
        密码:
      </label>
      <label style="margin-left: 20px;">
        <span v-if="subAccountInfo.password === ''">
          无密码
        </span>
        <span v-else>{{ subAccountInfo.password }}</span>
      </label>
    </div>
    <div class="form-group col-md-12"
         style="padding: 15px 10px 10px; border: 1px solid rgb(238, 238, 238); margin-top: 0px;">
      <label
          style="background-color: rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px; min-width: 70px; text-align: center;">
        备注:
      </label>
      <label style="margin-left: 20px;">
        {{ subAccountInfo.remark }}
      </label>
    </div>
  </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="visible = false">
          关闭
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>


export default {
  name:"share",
  data() {
    return {
      subAccountInfo: {
        shareCode: "",
        sessionCount: 0,
        password: "",
        remark: ""
      },
      sharUrl: "",
      visible:false
    }
  },
  mounted() {
    // 获取分享链接
    this.sharUrl = this.getSharUrl();
  },
  methods: {
    getSharUrl() {
      let domain = window.location.origin;
      let shareUrl = domain + "/accountshow/" + this.subAccountInfo.shareCode;
      return shareUrl;
    },
    setAccountInfo(info) {
      this.subAccountInfo = info;
      this.sharUrl = this.getSharUrl();
    },
    copyLink() {
      navigator.clipboard.writeText(this.sharUrl)
          .then(() => {
            this.$message({
              message: '已复制',
              type:'success'
            });
          })
          .catch(err => {
            console.error('复制失败:', err);
          });
    },
    copyAll() {
      let copyString = '邀请码：' + this.subAccountInfo.shareCode+
      '\n工单链接：' + this.sharUrl+
      '\n访问密码：' + this.subAccountInfo.password+
      '\n备注信息：' + this.subAccountInfo.remark
      console.log(copyString)
      navigator.clipboard.writeText(copyString)
          .then(() => {
            this.$message({
              message: '已复制',
              type:'success'
            });
          })
          .catch(err => {
            console.error('复制失败:', err);
          });
    }
  }
}
</script>
<style scoped>
a{
  cursor: pointer;
}
.el-dialog__headerbtn .el-dialog__close{
  display: block !important;
}
</style>