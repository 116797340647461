<template>
  <div v-if="visible">
    <div class="container-fluid" style=" margin-top: 20px; ">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h3>{{ title }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card social-widget">
      <div class="card-body">
        <div style="display:flex">
          <div class="search">
            <div class="block large-margins">
              <el-date-picker
                  v-model="form.start_time"
                  type="datetime"
                  placeholder="请选择开始日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  date-format="MMM DD, YYYY"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  time-format="HH:mm"
              />
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-date-picker
                  type="datetime"
                  v-model="form.end_time"

                  placeholder="请选择结束日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  date-format="MMM DD, YYYY"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  time-format="HH:mm"
                  style=""
              />
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-input v-model="form.session_name" placeholder="请输入用户昵称"/>
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-input v-model="form.nickname" placeholder="请输入昵称"/>
            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-select v-model="form.online_status" placeholder="在线状态" style="width: 180px">
                <el-option label="在线" :value="1"/>
                <el-option label="离线" :value="2"/>
              </el-select>

            </div>
            <div class="block large-margins" style="text-align: center;">
              <el-button type="primary" @click="search">搜索</el-button>
              <el-button type="warning" @click="form ={}">重置</el-button>
            </div>


          </div>

        </div>

        <div class="large-margins">
          <el-table :data="tableData" border scrollbar-always-on
                    style="width: 100%;margin-top:20px" ref="innerTable">

            <el-table-column align="center" label="APP" width="180">
              <template #default="scope">
                <el-tag v-if="scope.row.app_platform_id==1" type="success">Whatsapp</el-tag>
                <el-tag v-if="scope.row.app_platform_id==2" type="success">Telegram</el-tag>
                <el-tag v-if="scope.row.app_platform_id==3" type="success">Line</el-tag>
                <el-tag v-if="scope.row.app_platform_id==4" type="success">Facebook</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="session_name" label="用户昵称" width="150"/>


            <el-table-column align="center" prop="avatar" label="头像" width="150">
              <template #default="scope">
                <el-avatar
                    :src="scope.row.session_avatar"
                >
                  <template #default>
                    <img src="@/assets/images/user/user.png"/>
                  </template>
                </el-avatar>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="session_nick_name" label="昵称" width="180"/>
            <el-table-column align="center" prop="address" label="首登时间/最后活跃时间" width="180">
              <template #default="scope">
                <div>
                  {{ scope.row.first_login_at }}
                </div>
                <div>
                  {{ scope.row.last_active_at }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="address" label="联系人/今日">
              <template #default="scope">
                <div>
                  {{ scope.row.contact_count }}
                  /
                  {{ scope.row.contact_today_count }}
                </div>
              </template>
            </el-table-column>
            <!--            <el-table-column align="center" prop="address" label="总重复/今日" width="150">-->
            <!--              <template #default="scope">-->
            <!--                <div>-->
            <!--                  {{ scope.row.repeat_count }}-->
            <!--                  /-->
            <!--                  {{ scope.row.repeat_today_count }}-->
            <!--                </div>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column align="center" label="在线状态" width="180">
              <template #default="scope">
                <el-tag v-if="scope.row.online_status==1" type="success">在线</el-tag>
                <el-tag v-if="scope.row.online_status==2" type="danger">离线</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remark" label="备注" width="150"/>
            <el-table-column align="center" prop="created_at" label="创建时间" width="180"/>

            <!--            <el-table-column align="center" prop="app_version" label="版本号" width="150"/>-->
            <el-table-column align="center" label="操作" fixed="right" width="280">
              <template #default="scope">
                <el-button size="small" type="primary"
                           @click="handleContact(scope.row)">
                  联系人明细
                </el-button>

              </template>
            </el-table-column>
          </el-table>

        </div>
        <span class="m-t-10" style="display: flex;justify-content: center;">
           <el-pagination small background
                          @prev-click="handlePrevClick"
                          @next-click="handleNextClick"
                          @current-change="handleCurrentChange"
                          layout="prev, pager, next"
                          :total="total"/>
      </span>
      </div>
    </div>
    <contact ref="contactsDialog"></contact>
    <session-log ref="sessionLogDialog"></session-log>
  </div>
  <div v-if="!visible">
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="authentication-main mt-0">
          <div class="row">
            <div class="col-12">
              <div class="login-card">
                <div>
                  <div class="login-main">
                    <div class="theme-form">
                      <h6><i class="icofont icofont-ui-lock"></i> 请输入密码验证</h6>
                      <div class="form-group">
                        <div class="form-input position-relative">
                          <input class="form-control"
                                 type="password"
                                 name="login[password]"
                                 required=""
                                 v-model="code_password"
                                 placeholder="*********">
                          <div class="show-hide">
                            <span class="show">

                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button class="btn btn-primary btn-block" @click="fetchData">验证</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SessionLog from "@/pages/ShareList/sessionLog.vue";
import Contact from "@/pages/ShareList/contact.vue";
import {apiShareCode, apiShareCodeValid} from "@/api/share_code";

export default {
  name: "subAccount",
  components: {
    Contact,
    SessionLog,
  },
  data() {
    return {
      visible: false,
      id: '',
      row: [],
      tableData: [],
      logsData: [],
      title: '',
      total: 0,
      page: 1,
      page_size: 10,
      form: {},
      code_password: '',
    };
  },
  created() {
    if (this.$route.params == undefined || this.$route.params.id == undefined || this.$route.params.id == "") {
      this.$message.error(`邀请码不存在或已过期!`);
      return
    }
    this.valid()

    this.row = this.$route.query
    console.log(this.$route.params)
    this.title = '邀请码-' + this.$route.params.id

  },
  methods: {
    handleContact(row) {
      this.$refs.contactsDialog.openVisibleDialog(row)
    },
    handleSessionLog(row) {
      this.$refs.sessionLogDialog.openVisibleDialog(row)
    },
    fetchData() {
      apiShareCode({
        account_code: this.$route.params.id,
        page: this.currentPage,
        page_size: this.page_size,
        code_password: this.code_password,
        ...this.form
      }).then(t => {
        this.visible = true
        this.tableData = t.list
        this.total = t.total
      }).catch(e => {
        setTimeout(() => {
          // this.$router.push('/');
        }, 1000);
      })
    },
    valid() {
      apiShareCodeValid({
        account_code: this.$route.params.id,
        code_password: this.code_password,
      }).then(r => {
        if (r.valid == false) {
          this.visible = true
        }
      })
    },
    handlePrevClick() {
    },
    handleNextClick() {
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    search() {
      this.currentPage = 1

      this.fetchData()
    }

  }
}
</script>
<style scoped>

.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.large-margins {
  margin: 15px 8px;
}

.search-but {
  width: 120px
}

.el-input__wrapper {
  padding: 0 !important;
}

.el-input__prefix {
  padding-left: 10px !important;
}

.search {
  display: flex;
  flex-flow: wrap;
  justify-content: start;
  flex: 1.6 1 0%;
  min-width: 350px;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px;
}

.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.demo-datetime-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}

.demo-datetime-picker .block {
  padding: 30px 0;
  text-align: center;
}

.line {
  width: 1px;
  background-color: var(--el-border-color);
}
</style>