import {createRouter, createWebHistory} from "vue-router";
import Body from '../components/body';

/* Dashboards */
import Default from '../pages/dashboard/default';
// Widgets
import General from '@/pages/subAccount/general'
import SubAccount from '@/pages/subAccount/index'


//新页面引入
import Invitecode from '../pages/invitecode';
import ShareList from '../pages/ShareList';
import FanDetails from '../pages/FanDetails';
import Download from '../pages/download';
import Shorturl from '../pages/Shorturl'
import Pricing from '../pages/pricing'
import document from '../pages/document'
import Reply from '../pages/Reply'
import Corpus from '../pages/Reply/corpus'
import Role from '../pages/Role'
import Payment from '../pages/payment'
import UsdtRecharge from '../pages/payment/usdtRecharge'
import comboOrder from '../pages/comboOrder'
import paymentOrder from '../pages/paymentOrder'
import loginLog from '../pages/loginLog'


/* Auth */
import login from '../auth/login';
import Register from '../auth/register';


const routes = [
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login 2',
                component: login,
                meta: {
                    title: ' login | Cuba - Premium Admin Template',
                }
            },
            {
                path: 'register',
                name: 'register 1',
                component: Register,
                meta: {
                    title: ' Register | Cuba - Premium Admin Template',
                }
            }
        ]
    },

    {
        path: '/',
        component: Body,
        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: Default,
                meta: {
                    title: ' Cuba - Premium Admin Template',
                }
            },

        ]
    },
    {
        path: '/accountshow/:id',
        children: [
            {
                path: '',
                name: 'accountshow',
                component: ShareList,
            },

        ]
    },

    {
        path: '/Reply',
        name: 'Reply',
        component: Body,
        children: [
            {
                path: '',
                name: 'Reply',
                component: Reply,
                meta: {
                    title: 'Reply',
                }
            },
            {
                path: 'corpus',
                name: 'corpus',
                component: Corpus,
                meta: {
                    title: 'corpus',
                }
            },
        ]
    },
    {
        path: '/Role',
        name: 'Role',
        component: Body,
        children: [
            {
                path: '',
                name: 'Role',
                component: Role,
                meta: {
                    title: 'Role',
                }
            },
        ]
    },
    {
        path: '/Pricing',
        name: 'Pricing',
        component: Body,
        children: [
            {
                path: '',
                name: 'Pricing',
                component: Pricing,
                meta: {
                    title: 'Pricing',
                }
            },
        ]
    },
    {
        path: '/document',
        name: 'document',
        component: Body,
        children: [
            {
                path: '',
                name: 'document',
                component: document,
                meta: {
                    title: 'document',
                }
            },
        ]
    },

    {
        path: '/Shorturl',
        name: 'Shorturl',
        component: Body,
        children: [
            {
                path: '',
                name: 'Shorturl',
                component: Shorturl,
                meta: {
                    title: 'Agreement | Cuba - Premium Admin Template',
                }
            },
        ]
    },
    {
        path: '/Download',
        name: 'Download',
        component: Body,
        children: [
            {
                path: '',
                name: 'Download',
                component: Download,
                meta: {
                    title: 'Download | Cuba - Premium Admin Template',
                }
            },
        ]
    },
    //
    {
        path: '/invitecode',
        name: 'Invitecode',
        component: Body,
        children: [
            {
                path: '',
                name: 'invitecode',
                component: Invitecode,
                meta: {
                    title: 'Project list | Cuba - Premium Admin Template',
                }
            },
        ]
    },
    {
        path: '/ShareList',
        name: 'ShareList',
        component: Body,
        children: [
            {
                path: '',
                name: 'ShareList',
                component: ShareList,
                meta: {
                    title: 'ShareList',
                }
            },
        ]
    },
    {
        path: '/contact',
        name: 'FanDetails',
        component: Body,
        children: [
            {
                path: '',
                name: 'FanDetails',
                component: FanDetails,
                meta: {
                    title: 'FanDetails',
                }
            },
        ]
    },

    {
        path: '/subAccount',
        component: Body,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'general',
                name: 'general',
                component: General,
                meta: {
                    title: 'general Dashboard | Cuba - Premium Admin Template',
                }
            },
            {
                path: 'index',
                name: 'index',
                component: SubAccount,
                meta: {
                    title: 'chart Dashboard | Cuba - Premium Admin Template',
                }
            },
        ]
    },

    {
        path: '/Payment',
        component: Body,

        children: [
            {
                path: '',
                name: 'Payment',
                component: Payment,
                meta: {}
            },
            {
                path: 'usdtRecharge',
                name: 'usdtRecharge',
                component: UsdtRecharge,
                meta: {}
            },
        ]
    },
    {
        path: '/comboOrder',
        component: Body,

        children: [
            {
                path: '',
                name: 'comboOrder',
                component: comboOrder,
                meta: {}
            },
        ]
    },
    {
        path: '/paymentOrder',
        component: Body,

        children: [
            {
                path: '',
                name: 'paymentOrder',
                component: paymentOrder,
                meta: {}
            },
        ]
    },
    {
        path: '/loginLog',
        component: Body,

        children: [
            {
                path: '',
                name: 'Login 1',
                component: loginLog,
                meta: {}
            },
        ]
    },

]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    const path = ['/auth/login', '/auth/register'];
    const accountShowRegex = /^\/accountshow(\/\d+)?$/;

    if (path.includes(to.path) || accountShowRegex.test(to.path) || localStorage.getItem('User')) {
        return next();
    }

    console.log(to.path);
    next('/auth/login');

});
export default router