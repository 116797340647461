<template>
  <el-dialog v-model="visible" title="创建邀请码" width="1200">
    <div style="margin-left:30px">
      <el-form :model="form">

        <el-form-item label="邀请码类型">
          <el-radio-group v-model="form.consume_type">
            <el-radio checked value="1">端口</el-radio>
            <el-radio checked value="2">字数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分配端口" v-if="form.consume_type === '1'">
          <el-input required="true"  v-model="form.session_limit" style="width: 240px" placeholder="请输入要分配的端口数"/>
        </el-form-item>
        <el-form-item label="拥有权限">
          <el-checkbox-group>
            <el-checkbox  checked disabled name="type">
              多设备登录
            </el-checkbox>
            <el-checkbox v-if="form.consume_type === '1'" checked disabled name="type">
              通讯录明细
            </el-checkbox>
            <el-checkbox v-if="form.consume_type === '1'" checked disabled name="type">
              分析重复
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              移除账号
            </el-checkbox>
            <el-checkbox v-if="form.consume_type === '1'" checked disabled name="type">
              <el-tooltip content="重新登录客户端将自动上传账号联系人分析数据=计数器" placement="top">
                <span style="color:red">数据分析 ?</span>
              </el-tooltip>
            </el-checkbox>
            <el-checkbox v-if="form.consume_type === '1'" checked disabled name="type">
              重粉标记
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="可用APP">
          <el-checkbox-group>
            <el-checkbox checked disabled name="type">
              WhatApp
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Zalo
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Line
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Telegrom
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              TikTok
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Instagram
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Facebook
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Twitter
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Messenger
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Meta Business
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Google Voice
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              BoTim
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Snapcaht
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              Line Business
            </el-checkbox>
            <el-checkbox checked disabled name="type">
              WEB
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <div style="display: flex;">
          <el-form-item label="备注信息">
            <el-input v-model="form.remark" style="width: 240px" placeholder="请输入备注信息"/>
          </el-form-item>
          <el-form-item label="分享密码" style="margin-left:15px">
            <el-input v-model="form.code_password" style="width: 240px" placeholder="请输入分享密码"/>
          </el-form-item>
          <el-form-item label="置零时间" style="margin-left:15px">
            <el-time-picker
                v-model="form.reset_time_at"
                placeholder="结束时间"
                :value-format="pickerFormatText"
                :format="pickerFormatText"
                :picker-options="{
            selectableRange: `${minPickerTime} - 23:59:00`,
            format: pickerFormatText
          }"
            >
            </el-time-picker>
          </el-form-item>
        </div>
        <el-form-item label="启用状态">
          <el-select v-model="form.status" placeholder="启用状态" style="width: 240px">
            <el-option label="正常"  value="1"/>
            <el-option label="禁用" value="2"/>
          </el-select>
        </el-form-item>

      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="visible = false">
          取消
        </el-button>
        <el-button type="primary" @click="save">保存</el-button>

      </div>
    </template>
  </el-dialog>

</template>
<script>

import {apiSubAccountCreate} from "@/api/sub_account";

export default {
  name: 'createSubAccountDialog',
  data() {
    return {
      visible: false,
      form: {
        consume_type: "1",
        session_limit: '',
        remark: '',
        status: '1',
        reset_time_at: '23:59:59',
        code_password: '',
        app: [],
      },
      date: new Date(),
      formdata: {
        starttime: '',
        endtime: ''
      },
      minPickerTime: '08:00:00',
      pickerFormatText: 'HH:mm:ss'

    }
  },
  methods: {
    openVisibleDialog() {
      this.form = {
        consume_type: "1",
        session_limit: '',
        remark: '',
        status: '1',
        reset_time_at: '23:59:59',
        code_password: '',
        app: [],
      }
      this.visible = true;
    },
    save() {

      if (this.form.consume_type == 1 && !parseInt(this.form.session_limit)){
        this.$message({
          type: 'error',
          message: '请填写端口数'
        });
        return;
      }

      apiSubAccountCreate({
        code_password: this.form.code_password,
        status:  parseInt(this.form.status),
        consume_type: parseInt(this.form.consume_type),
        session_limit: parseInt(this.form.session_limit),
        share_password: this.form.share_password,
        reset_time_at: this.form.reset_time_at,
        remark:  this.form.remark,
      }).then(r => {
        console.log(r);
        this.$message({
          type: 'success',
          message: '创建邀请码成功'
        });
        this.visible = false;
        this.$parent.fetchData()
      }).catch(e => {
        this.$message.error({
          type: 'success',
          message: e.data
        });
      })
    }
  }
}

</script>
<style scoped lang="scss">

</style>