const Axios = require('axios');
const {ElMessage} = require('element-plus');
const {getToken, removeToken} = require('@/utils/cache/localStorage');
const {getRendererEnvConfig} = require('@/utils/config');
// const {isFunction} = require('../is');
const router = require('@/router/index');
const {inject} = require("vue");

const {VUE_APP_BASE_API} = getRendererEnvConfig();

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
console.log('API_URL', VUE_APP_BASE_API)
const defaultConfig = {
    baseURL: VUE_APP_BASE_API,
    timeout: 60 * 1000,
    headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
    // 数组格式参数序列化
    // paramsSerializer: (params) => qs.stringify(params, { indices: false })
};

class PureHttp {
    constructor() {
        this.httpInterceptorsRequest();
        this.httpInterceptorsResponse();
    }

    /** 初始化配置对象 */
    static initConfig = {};

    /** 保存当前Axios实例对象 */
    static axiosInstance = Axios.create(defaultConfig);

    /** 请求拦截 */
    httpInterceptorsRequest() {
        PureHttp.axiosInstance.interceptors.request.use(
            (config) => {
                const $config = config;
                const token = getToken();
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                    return $config;
                } else {
                    return $config;
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /** 响应拦截 */
    httpInterceptorsResponse() {
        const instance = PureHttp.axiosInstance;
        instance.interceptors.response.use(
            (response) => {
                const $config = response.config;
                // 优先判断post/get等方法是否传入回调，否则执行初始化设置等回调
                if (typeof $config.beforeResponseCallback === 'function') {
                    $config.beforeResponseCallback(response);
                    return response.data;
                }
                if (PureHttp.initConfig.beforeResponseCallback) {
                    PureHttp.initConfig.beforeResponseCallback(response);
                    return response.data;
                }
                return response.data;
            },
            (error) => {
                const $error = error;
                $error.isCancelRequest = Axios.isCancel($error);
                // 所有的响应异常 区分来源为取消请求/非取消请求
                return Promise.reject($error);
            }
        );
    }

    /** 通用请求工具函数 */
    request(method, url, param, axiosConfig) {
        const config = {
            method,
            url,
            ...param,
            ...axiosConfig
        };

        console.log('url',url)


        // 设置为空
        if (!Object.keys(param || {}).length) {
            config.data = {};
        }

        if (config.method === 'get') {
            config.params = config.data;
        }
        const _this = this;
        // 单独处理自定义请求/响应回调
        return new Promise((resolve, reject) => {
            PureHttp.axiosInstance
                .request(config)
                .then((response) => {
                    if (response.code === 200) {
                        resolve(response.data);
                        return
                    }
                    if (response.code === 500) {
                        if (response.msg == 'you are unauthorized') {
                            console.log('router', router.default.replace('/auth/login'))
                            ElMessage.error('请重新登录');
                            return;
                        }
                        ElMessage.error({
                            message: '提示：' + response.msg,
                            zIndex: 2999, // 设置一个较高的 zIndex 值
                        });
                        reject(response)
                    }
                }).catch(err => {

                // console.log('router',router.default.push('/auth/login'))
                ElMessage.error('网络连接错误');

            })
        });
    }

    /** 单独抽离的post工具函数 */
    post(url, params, config) {
        try {
            if(params !=undefined && params.data !=undefined){
                params.data = this.filterEmptyFields(params.data)
                console.log('param',params.data)
            }

            return this.request('post', url, params, config);

        } catch (e) {
            console.error(e)
            return e
        }
    }

     filterEmptyFields(obj) {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value != null && value !== 'null' && value !== undefined && value !== '')
        );
    }

    /** 单独抽离的 put 工具函数 */
    put(url, params, config) {
        return this.request('put', url, params, config);
    }

    /** 单独抽离的 delete 工具函数 */
    del(url, params, config) {
        return this.request('delete', url, params, config);
    }

    /** 单独抽离的post工具函数 */
    postForm(url, params, config) {
        const config_temp = config || {};
        config_temp.headers = {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
        };
        return this.request('post', url, params, config_temp);
    }

    /** 单独抽离的get工具函数 */
    get(url, params, config) {
        return this.request('get', url, params, config);
    }
}

module.exports = new PureHttp();
